import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import element from 'element-plus'
import 'element-plus/dist/index.css'
import './assets/css/index.css'
const app = createApp(App,{
    productionTip: true
})
app.config.globalProperties.showPrice = function(item){
    const user_type = store.state.userInfo?.user?.user_type
	const is_agent = store.state.userInfo?.user?.is_agent
	const vip_id = store.state.userInfo?.user?.vip_id
	if(is_agent){
		return item.agent_price;
	}
	if(vip_id ){
		return item.vip_price;
	}
    // console.log("item",item)
	return item.price;
}
app.use(store).use(router).use(element).mount('#app')
