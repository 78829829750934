<template>
    <footer class="footer-content">
        <!-- 底部 -->
        <div class="social-content">
            <img class="logo" src="../../assets/images/base/logo.svg" />
            <div>
                <!-- <img src="../../assets/images/base/footer.svg"/> -->
                <img style="  height: 71px;" @click="toOtherPath('https://www.facebook.com/profile.php?id=61565508937983')" src="../../assets/images/base/facebook.jpg" />
                <img style="  height: 80px;position: relative;top: 3px;" src="../../assets/images/base/instagram.jpg" />
                <img style="  height: 60px;position: relative;top: 2px;" @click="toOtherPath('https://www.youtube.com/@CNBDMall')"  src="../../assets/images/base/youtube.jpg" />
                <img style="  height: 86px;position: relative;top: 2px;" @click="toOtherPath('https://www.tiktok.com/@user4815998803263')"  src="../../assets/images/base/tikTok.jpg" />
            </div>
            <p class="tips">More News on CNBD Social Media</p>
        </div>
        <div class="wrapper">
            <div>
                <p class="title">CONTACT</p>
                <p>phone：01726829478</p>
                <p>phone：+86 18067837933</p>
                <p>Email ： rekey_global@163.com</p>
            </div>
            <div class="nav-content" v-for="(item,index) in list" :key="index">
                <p class="title">{{item.name}}</p>
                <p @click="toPath(itemC.href,itemC.name)" class="link" v-for="(itemC,indexC) in item.child" :key="indexC" >{{itemC.name}}</p>
                <!-- <p>Cart</p>
                <p>Wishlist</p>
                <p>Shipping charge</p>
                <p>FAQ</p> -->
            </div>
            <!-- <div>
                <p class="title">CUSTOMER</p>
                <p>Account</p>
                <p>Cart</p>
                <p>Wishlist</p>
                <p>Shipping charge</p>
                <p>FAQ</p>
            </div>
            <div>
                <p class="title">INFORMATION</p>
                <p>About Us</p>
                <p>Contact Us</p>
                <p>Privacy Policy</p>
                <p>Return & Refund Policy</p>
                <p>Terms & Conditions</p>
            </div> -->
            <div>
                <p class="title">MOBILE APPS</p>
                <img @click="toOtherPath(download_ios)" style="margin-bottom: 15px;" src="../../assets/images/base/bangladesh.svg" />
                <img @click="toOtherPath(download_apk)" src="../../assets/images/base/bangladeshTwo.svg" />
            </div>
        </div>
        <div class="marking">
            <p>Copyright © © 2024 Inc.All Rights Reserved. CNBD.</p>
            <p>CNBDMallBrand</p>
        </div>
    </footer>
</template>

<script setup lang="js">
import { useRouter } from 'vue-router';
import { getNavList,getSetting } from "@/api/home.js"
import { ref } from "vue"
const router = useRouter()
const list = ref([])
const initData = () => {
    getNavList({
        id:4
    }).then((res)=>{
        list.value = res
        console.log("res--getNavList",res)
    })
}
initData()
const toPath = (id,name)=>{
    if( isNaN(+id)){
        window.open(id, "_blank");
        // router.push(`/user?on=13&id=${id}&name=${name}`)
    }
    else router.push(`/user?on=13&id=${id}&name=${name}`)
}
const toOtherPath = (res) => {
    window.open(res, '_blank', 'scrollbars=yes,resizable=1,modal=false,alwaysRaised=yes')
}
const download_apk = ref('')
const download_ios = ref('')
const getDown = async ()=>{
   const res = await getSetting()
   download_apk.value = res.download_apk
   download_ios.value = res.download_ios
}
getDown()
</script>

<style scoped lang="scss">
.footer-content {
    padding-right: 12px;

    .social-content {
        padding: 36px 0px;
        // height: 284px;
        background: #fff;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .logo {
            width: 184px;
            height: 98px;
            margin-bottom: 12px;
        }

        >div:nth-child(2) {
            display: flex;
            align-items: center;
            img {
                // width: 590px;
                // height: 36px;
                margin-left: 20px;
                cursor: pointer;
            }
        }

        .tips {
            margin-top: 30px;
            color: #000000;
            // font-family: Source Han Sans, Source Han Sans;
            font-weight: 400;
            font-size: 24px;
            color: #000000;
            line-height: 36px;
        }
    }
    .wrapper{
        padding: 34px 61px;
        display: flex;
        justify-content: center;
        >div:not(:last-child){
            margin-right: 73px;
        }
        .title{
            font-weight: 900;
            font-size: 17px;
            color: #303133;
            line-height: 26px;
            margin-bottom: 8px;
            text-transform: uppercase;
        }
        p{
            font-weight: 400;
            font-size: 16px;
            color: #303133;
            line-height: 24px;
            text-transform: capitalize;
        }
        img{
            display: block;
        }
    }
    .marking{
        border-top: 1px solid #E8E8E8;
        padding-top: 10px;
        text-align: center;
        font-weight: 400;
        font-size: 17px;
        color: #303133;
        line-height: 26px;
    }
    .nav-content{
        .link{
            cursor: pointer;
            &:hover{
                color: var(--el-color-primary);
            }
        }
    }
}
</style>